import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://google.com/">
        GTP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = () => (
  <Box sx={{ bgcolor: 'primary.main', p: 6, 'color': 'white' }} component="footer" marginTop={10}>
    <Typography variant="h6" align="center" gutterBottom>
      ГТП проект
    </Typography>
    <Typography
      variant="subtitle1"
      align="center"
      color="white"
      component="p"
    >
      Всички права запазени! Не кради от този сайт!
    </Typography>
    <Copyright />
  </Box >
)

export default Footer

import React, { useReducer } from 'react';
import './App.css';
import {
  useRoutes, BrowserRouter
} from "react-router-dom";
import Home from './components/pages/Home';
import CreateReservation from './components/pages/CreateReservation';
import Navigation from './components/Navigation';
import styled, { ThemeProvider } from 'styled-components';
import { createTheme, CssBaseline } from '@mui/material';
import Footer from './components/Footer';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import SignUp from './components/pages/SignUp';
import LogIn from './components/pages/LogIn';
import {
  AppDispatchCtx,
  appReducer,
  AppStateCtx,
} from './context/AppContext'

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/create-reservation", element: <CreateReservation /> },
    { path: "/auth/signup", element: <SignUp /> },
    { path: "/auth/login", element: <LogIn /> },
    // ...
  ]);
  return routes;
};

const theme = createTheme()

const AppWrapper = () => {
  const [state, dispatch] = useReducer(appReducer, {})
  return (
    <AppStateCtx.Provider value={state}>
      <AppDispatchCtx.Provider value={dispatch}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <Navigation />
            <MainContent>
              <App />
            </MainContent>
            <Footer></Footer>
          </BrowserRouter>
        </ThemeProvider>
      </AppDispatchCtx.Provider>
    </AppStateCtx.Provider>
  );
};

export const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
  (props, ref) => (
    <RouterLink
      ref={ref}
      to="/material-ui/getting-started/installation/"
      {...props}
    />
  ),
);

export default AppWrapper;

const MainContent = styled.main`
  min-height: 50vh;
  margin-top: 50px;
`

import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LinkBehavior } from '../../App';
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function HomePage() {
  return (
      <>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Направете резервация за годишен технически преглед
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="column"
              spacing={2}
              justifyContent="center"
            >

              <Button to="/create-reservation" component={LinkBehavior as any} variant="contained" >Създайте резервация</Button>
              <Button variant="outlined">Вижте всички резервации</Button>
            </Stack>
          </Container>
        </Box>
      </>
  );
}

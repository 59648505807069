import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Button, Card, CardActions, CardContent, FormLabel, Radio, Stack } from '@mui/material';


const CarTypes = ['Тип 1', 'Тип 2', 'Тип 3', 'Тип 4']

const CarTypeSelector = () => (
  <Box sx={{ textAlign: 'left' }} component="footer" marginTop={10}>
    <Stack spacing={2}>
      <FormLabel component="legend">Тип автомобил</FormLabel>
      {CarTypes.map(carType =>
        <Card
          sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <CardContent sx={{ flexGrow: 1 }}>
            <Stack
              direction="column"
              spacing={2}
              justifyContent="center"
              flexDirection="row"
            >
              <Radio
                value="b"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'B' }}
              />
              <div style={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h6" component="h5">
                  { carType }
                </Typography>
                <Typography gutterBottom variant="body1" component="h6">
                  Допълнително описание за този тип автомобил
                </Typography>
              </div>
            </Stack>
          </CardContent>
        </Card>
      )}
    </Stack>
  </Box >
)

export default CarTypeSelector

// tslint:disable: interface-over-type-literal */
import { createContext, useContext } from 'react'


export interface IUser {
  firstName: string
  lastName: string
  username: string
}

// Types
export type State = {
  authUser?: IUser
}

export const DEFAULT_APP_STATE: State = {
}

// Actions
export type updateStateAction = {
  type: 'updateState'
  payload: State
}
export type updateUserAction = {
  type: 'updateUser'
  payload: IUser | undefined
}

export type Action =
  | updateStateAction
  | updateUserAction


export type Dispatch = (action: Action) => void

// Contexts
export const AppStateCtx = createContext<State | undefined>(undefined)

export const AppDispatchCtx = createContext<Dispatch | undefined>(undefined)

// Reducer
export const appReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'updateState': {
      return {
        ...action.payload,
      }
    }
    case 'updateUser': {
      return {
        ...state,
        authUser: action.payload,
      }
    }
    default: {
      // @ts-ignore next-line
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

// Hooks
export const useAppState = () => {
  const context = useContext(AppStateCtx)

  if (!context) {
    throw new Error('useAppState must be used within a AppProvider')
  }

  return context
}

export const useAppDispatch = () => {
  const context = useContext(AppDispatchCtx)

  if (!context) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }

  return context
}

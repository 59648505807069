import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  Link as RouterLink,
} from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';
import { LinkBehavior } from '../App';
import { useAppDispatch, useAppState } from '../context/AppContext';
import { IconButton, Menu, MenuItem, TextField } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

const Navigation = () => {

  const appState = useAppState()
  const appDispatch = useAppDispatch()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    appDispatch({
      type: 'updateUser',
      payload: undefined
    })
    setAnchorEl(null);
  };
  return (
    <AppBar position="relative">
      <Toolbar>
        <DirectionsCarFilledIcon sx={{ mr: 2 }} />
        <Link to="/" component={LinkBehavior as any} variant="h6" color="inherit" noWrap underline='none'>
          Годишни технически прегледи
        </Link>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

        </Typography>
        {/* <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value="bg"
        label="Age"
        color="info"
      >
        <MenuItem value="bg" color="white">Български</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select> */}
        {!appState.authUser &&
          <div>
            <Button color="inherit" component={LinkBehavior as any} to="/auth/login">Вход</Button>
            <Button color="inherit" component={LinkBehavior as any} to="/auth/signup">Регистрация</Button>
          </div>
        }
        {appState.authUser && <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle sx={{ mr: 1 }} />
            <Typography component="div" variant="body1">
              {appState.authUser.firstName} {appState.authUser.lastName}
            </Typography>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>}
      </Toolbar>
    </AppBar>
  )
}

export default Navigation

import { Box, Button, Card, CardActions, CardContent, CardMedia, Checkbox, Collapse, Container, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LinkBehavior } from '../../App';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useAppState } from '../../context/AppContext';
import CarTypeSelector from '../CarTypeSelector';

const cards = [1, 2, 3, 4, 4, 5, 5, 4, 2, 3, 5];

const POSSIBLE_LOCATIONS = ['жк. Люлин 23', 'при Ванката', 'при Тео']

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const CreateReservation = () => {

  const appState = useAppState()
  const { authUser } = appState

  const [selectedCar, setSelectedCar] = useState<string | undefined>()
  const [carType, setCarType] = useState<string | undefined>();
  const [value, setValue] = React.useState<Dayjs | null>();
  const [time, setTime] = useState<string>('1')
  const [showingAvailableOptions, setShowingAvailableOptions] = useState<boolean>(false)

  const showAvailableOptions = () => {
    setShowingAvailableOptions(true)
  }

  const handleSelectCar = (newValue: string | undefined) => {
    setSelectedCar(newValue)
  }


  const handleSetCarType = (event: SelectChangeEvent) => {
    setCarType(event.target.value as string);
  };


  const handleChangeDate = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  const setSelectedTime = (event: SelectChangeEvent) => {
    setTime(event.target.value)
  }

  const [locations, setLocations] = React.useState<boolean[]>([false, false, false]);

  const handleCheckboxSet = (i: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = [...locations]
    newChecked[i] = !newChecked[i]
    setLocations(newChecked)
  };

  return (<Container maxWidth="lg">
    <Grid container spacing={2}>
      <Collapse in={authUser && !selectedCar} timeout="auto" unmountOnExit>
        <Grid xs={9} xsOffset={1.5}>
          <Grid container spacing={4}>
            <Grid xs={12}>
              <Typography gutterBottom variant="h3" component="h2">
                Избери автомобил
              </Typography>
            </Grid>
            <Grid xs={5}>
              <TextField id="outlined-basic" label="Search" variant="outlined" fullWidth />
            </Grid>
            <Grid xs={12}>
              <Button size="large" variant="outlined" fullWidth onClick={() => handleSelectCar('Нов автомобил')}>Въведи нов автомобил</Button>
            </Grid>
            {cards.map((card) => {
              const licensePlate = `CB ${(1000 + Math.random() * 9000) | 0} MO`
              return (
                <Grid key={card} xs={6} sm={3} md={3}>
                  <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <DirectionsCarIcon sx={{ mr: 3 }} fontSize='large' />
                      <Typography gutterBottom variant="h6" component="h5">
                        {licensePlate}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small"><EditIcon fontSize="small" /> </Button>
                      <Button size="small" variant="contained" onClick={() => handleSelectCar(licensePlate)}>Избери</Button>
                    </CardActions>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Collapse>
      <Grid xs={9} xsOffset={1.5}>
        <Collapse in={!authUser || !!selectedCar} timeout="auto" unmountOnExit>
          <Item>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <DirectionsCarIcon sx={{ mr: 1, margin: '6px 5px 6px 0' }} fontSize='large' />
                    <Typography gutterBottom variant="h6" component="h5" margin='0'>
                      {selectedCar}
                    </Typography>
                    <Button size="small" onClick={() => setSelectedCar(undefined)}><EditIcon fontSize="small" /> </Button>
                  </Card>
                  <CarTypeSelector></CarTypeSelector>
                  {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Тип Автомобил</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={carType}
                      label="Тип Автомобил"
                      onChange={handleSetCarType}
                      style={
                        { textAlign: 'left' }
                      }
                    >
                      <MenuItem value={'10'}>Лека кола</MenuItem>
                      <MenuItem value={'20'}>Камион</MenuItem>
                      <MenuItem value={'30'}>Тир</MenuItem>
                      <MenuItem value={'40'}>Друго</MenuItem>
                    </Select>
                  </FormControl> */}
                  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" fullWidth style={{ textAlign: 'left' }}>
                    <FormLabel component="legend">Локации</FormLabel>
                    {
                      POSSIBLE_LOCATIONS.map((location, i) => (
                        <FormControlLabel
                          label={location}
                          key={'location-' + i}
                          control={
                            <Checkbox
                              checked={locations[i]}
                              onChange={(e) => handleCheckboxSet(i, e)}
                            />
                          }
                        />
                      ))
                    }
                  </FormControl>
                  <MobileDatePicker
                    label="Дата"
                    inputFormat="MM/DD/YYYY"
                    value={value}
                    minDate={dayjs()}
                    onChange={handleChangeDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="hour-select-label">Час</InputLabel>
                    <Select
                      labelId="hour-select-label"
                      id="hour-select"
                      label="Час"
                      value={time}
                      style={
                        { textAlign: 'left' }
                      }
                      onChange={setSelectedTime}
                    >
                      <MenuItem value={'1'}>Без значение</MenuItem>
                      <MenuItem value={'10'}>17:00</MenuItem>
                      <MenuItem value={'20'}>19:00</MenuItem>
                    </Select>
                  </FormControl>
                  <Button variant="contained" onClick={() => showAvailableOptions()}>Покажи възможни слотове</Button>
                </Stack>
              </LocalizationProvider>
            </FormControl>
          </Item>
        </Collapse>
      </Grid>
      <Grid xs={9} xsOffset={1.5}>
        <Grid container spacing={4}>
          <Grid xs={3}>
            {showingAvailableOptions && <Card
              sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h6" component="h5">
                  при Ванката
                </Typography>
                <Typography gutterBottom variant="body1" component="h6">
                  19:00
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" variant="contained">Запази</Button>
              </CardActions>
            </Card>}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid xs={7} xsOffset={2.5} marginTop={5} textAlign='center'>
        <Stack spacing={2}>
          <Button to="/create-reservation" component={LinkBehavior as any} variant="contained" >Създайте резервация за локация При Тео</Button>
          <Button to="/create-reservation" component={LinkBehavior as any} variant="contained" >Създайте резервация за локация При Ванката</Button>
        </Stack>
      </Grid> */}
    </Grid >
  </Container >)
}

export default CreateReservation
